import React, { useState } from "react";

import { Participant } from "../data/Participant";
import Modal from "./Modal";
import { doFetch } from "../utils/api";
import ModalBody from "./Modal/ModalBody";

interface ParticipantsTableOptions {
  participants: Participant[]
}

function ParticipantsTable({ participants }: ParticipantsTableOptions) {
  const [currentParticipant, setCurrentParticipant] = useState<Participant | null>(null);

  const setCheckedIn = (participant: Participant, checkedIn: boolean) => {
    if (!checkedIn) {
      // eslint-disable-next-line no-restricted-globals
      const result = confirm(`Weet je zeker dat je ${participant.firstName} ${participant.lastName} wilt uitchecken?`);

      if (!result)
        return;
    }

    participant.checkedIn = checkedIn;

    setCurrentParticipant(participant);
    doFetch("/participants/" + participant.id, {
      method: "PUT",
      body: JSON.stringify(participant)
    }).then((data) => {
      setCurrentParticipant(null);
    })
      .catch((err) => {
        alert("error: " + err);
        setCurrentParticipant(null);
      });
  }

  return (
    <div className="participantsTable">
      <Modal
        isOpen={currentParticipant != null}
        onClose={() => { }}
      >
        <ModalBody className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>{(currentParticipant?.checkedIn) ? "Checking out" : "Checking in"}...</p>
        </ModalBody>
      </Modal>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>OrderID</th>
            <th>Voornaam</th>
            <th>Achternaam</th>
            <th>Bedrijfsnaam</th>
            <th>Kenteken</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {participants.sort((a, b) => (a.checkedIn === b.checkedIn ? 0 : (a.checkedIn ? 1 : -1))).map(p => (
            <tr key={p.id} style={{ backgroundColor: (p.checkedIn) ? "rgb(204, 255, 204)" : '' }}>
              <td>{p.orderId}</td>
              <td>{p.firstName}</td>
              <td>{p.lastName}</td>
              <td>{p.company}</td>
              <td>{p.plate}</td>
              <td>
                {
                  (!p.checkedIn) ? <button className="btn btn-success" onClick={() => setCheckedIn(p, true)}>Check in</button> : <button className="btn btn-danger" onClick={() => setCheckedIn(p, false)}>Check uit</button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
  // return (
  //   <table className="table table-striped">
  //     <thead>
  //       <tr>
  //         <th>OrderID</th>
  //         <th>Voornaam</th>
  //         <th>Achternaam</th>
  //         <th>Bedrijfsnaam</th>
  //         <th>Kenteken</th>
  //         <th></th>
  //       </tr>
  //     </thead>
  //     { participants.map(p => (
  //       <tr>
  //         <td>{p.orderId}</td>
  //         <td>{p.firstName}</td>
  //         <td>{p.lastName}</td>
  //         <td>{p.company}</td>
  //         <td>{p.plate}</td>
  //         <td>
  //           {/* TODO actions */}
  //         </td>
  //       </tr>
  //     ))}
  //   </table>
  // );
}

export default ParticipantsTable;