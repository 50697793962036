import { useEffect, useState } from "react";

interface SearchBoxOptions {
  title: string;
  onChange: (val: string) => void; 
  className?: string,
  value?: string
}

function SearchBox({ title, onChange, className = "", value = "" }: SearchBoxOptions) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    onChange(localValue);
  }, [localValue, onChange]);

  const clearInput = () => {
    setLocalValue("");
  };

  return (
    <div className={"searchBox " + className}>
      <h3>{title}</h3>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          onChange={(e) => { setLocalValue(e.target.value) }}
          value={localValue}
        />
        <button
          onClick={clearInput}
          className="input-group-text"
        >X</button>
      </div>
    </div>
  );
}

export default SearchBox;