import { ReactNode, useEffect, useRef, useState } from "react";
import { Modal as bsModal } from 'bootstrap';

interface ModalProps {
    children: ReactNode[] | ReactNode, // TODO add type
    title?: string,
    isOpen?: boolean,
    onClose: () => void
};

function Modal({ children, title, isOpen : isOpenProp = false, onClose }: ModalProps) {
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const ref = useRef(null);
    const instance = useRef<bsModal | null>(null);

    useEffect((): any => {
        if(!ref.current) { return null; }

        instance.current = new bsModal(ref.current);

        return () => instance.current?.dispose();
    }, []);

    useEffect(() => {
        setIsOpen(isOpenProp);
    }, [isOpenProp]);

    useEffect(() => {
        setModalState(isOpen);
    }, [isOpen]);

    const setModalState = (shouldOpen: boolean) => {
        if(!instance.current) { return; }

        if(shouldOpen) {
            instance.current.show();
        } else {
            instance.current.hide();
        }
    };

    const localOnClose = () => {
        setModalState(false);
        onClose();
    };
    
    return (
        <div className="modal" ref={ref}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        { title && <h5 className="modal-title">{ title }</h5>}
                        <button type="button" className="btn-close" onClick={localOnClose} aria-label="Close"></button>
                    </div>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default Modal;