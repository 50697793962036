import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import ModalBody from "../components/Modal/ModalBody";
import BarcodeScanner from "../components/BarcodeScanner";
import { NotFoundException } from "@zxing/library";
import SearchBox from "../components/SearchBox";
import { doFetch } from "../utils/api";
import ParticipantsTable from "../components/ParticipantsTable";
import { Participant } from "../data/Participant";

function Home() {
  const [isScanning, setIsScanning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticpants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [barcodeQuery, setBarcodeQuery] = useState("");
  const [orderIdQuery, setOrderIdQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [plateQuery, setPlateQuery] = useState("");
  const [companyQuery, setCompanyQuery] = useState("");

  useEffect(() => {
    doFetch("/participants")
      .then((data) => {
        setParticpants(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredParticipants(participants);
  }, [participants]);

  useEffect(() => {
    if (!participants)
      return;

    function includesSearchTerm(value: string, searchTerm: string) {
      if (value === null) {
        return true; // Null or undefined value, exclude the record
      }

      if (searchTerm === '') {
        return true; // Empty search term, include all records
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    }

    const trimPlate = (val: any) => (val) ? val.replace(" ", "").replace("-", "") : val;


    const result = participants.filter((item: Participant) => {
      return (
        includesSearchTerm(item.barcode, barcodeQuery) &&
        includesSearchTerm(item.orderId, orderIdQuery) &&
        includesSearchTerm(trimPlate(item.plate), trimPlate(plateQuery)) &&
        (includesSearchTerm(item.firstName, nameQuery) || includesSearchTerm(item.lastName, nameQuery)) &&
        includesSearchTerm(item.company, companyQuery)
      );
    });

    setFilteredParticipants(result);
  }, [orderIdQuery, nameQuery, plateQuery, companyQuery, participants, barcodeQuery]);

  const onBarcodeError = (e: any) => {
    if (e instanceof NotFoundException) { return; }

    console.error(e);
  }

  const onBarcodeResult = (e: any) => {
    setIsScanning(false);
    setBarcodeQuery(e.text);
  }

  return (
    <div className="homepage m-4">
      <Modal
        isOpen={isScanning}
        title="Scan barcode"
        onClose={() => setIsScanning(false)}
      >
        <ModalBody>
          {isScanning && <BarcodeScanner onError={onBarcodeError} onResult={onBarcodeResult} />}
        </ModalBody>
      </Modal>

      <div className="row">
        <div className="col-3">
          <h2>WKOW Check-in tool</h2>
          { participants && <p>Ingecheckt: { participants.filter((item: Participant) => item.checkedIn).length }</p>}
          <hr className="mb-4" />
          <button className={`btn ${(!isScanning) ? 'btn-success' : 'btn-danger'}`} onClick={() => setIsScanning(!isScanning)}>Scan barcode</button>
          <SearchBox
            title="Barcode"
            className="my-4"
            value={barcodeQuery}
            onChange={setBarcodeQuery}
          />
          <SearchBox
            title="Naam"
            className="mb-4"
            onChange={(val) => setNameQuery(val)}
          />
          <SearchBox
            title="Bedrijf"
            className="mb-4"
            onChange={(val) => setCompanyQuery(val)}
          />
          <SearchBox
            title="Kenteken"
            className="mb-4"
            onChange={(val) => setPlateQuery(val)}
          />
          <SearchBox
            title="Order ID"
            className="mb-4"
            onChange={(val) => setOrderIdQuery(val)}
          />
        </div>
        <div className="col-9">
          {(isLoading) ? <p>Loading...</p> : <ParticipantsTable participants={filteredParticipants} />}
        </div>
      </div>
    </div>
  );
}

export default Home;