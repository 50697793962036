import { Result } from '@zxing/library';
import useZxing from '../hooks/useZxing';
import React from 'react';

interface BarcodeScannerProps {
    onResult: (input: any) => void,
    onError: (input: any) => void
}

function BarcodeScanner({ onResult, onError }: BarcodeScannerProps) {
    const { ref } = useZxing({ onResult, onError });

    return <div className="barcodeScanner position-relative">
        <video
            ref={ref}
            width="100%"
            height="100%"
        />
    </div>
        ;
};

export default BarcodeScanner;