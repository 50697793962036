function doFetch(...args) {
  return doFetchRaw(...args)
    .then((res) => res.json());
}

function doFetchRaw(requestUrl, options = {}) {
  if (!options.headers) {
    options.headers = {};
  }

  options.headers["Authorization"] = "Bearer Bzj94CiNdDInLAaG5GvoBKeUPMGNQ64j5sEi4XSD5G9bd3QCMWTWpylsdK0SopcK";

  return fetch(process.env.REACT_APP_API_URL + requestUrl, options);
}

export {
  doFetch,
  doFetchRaw
}