import React from "react";

interface ModalBodyOptions {
  children: any,
  className? : string
}

function ModalBody({ children, className = "" }: ModalBodyOptions) {
  return (
    <div className={`modal-body ${className}`}>
      { children }
    </div>
  )
}

export default ModalBody;